@import "tailwindcss/base";
/*@import "tailwindcss/components";
@import "tailwindcss/utilities";*/

/* @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens; */

@import "./elements/base.css";
@import "./elements/buttons.css";
@import "./elements/layout.css";
@import "./elements/typography.css";
@import "./elements/gradients.css";
@import "./elements/list.css";
@import "./elements/graph-label.css";
@import "./elements/search-results.css";
@import "./elements/preloader.css";

@import "./elements/aos.css";
@import "./elements/flickity.css";

@import "tailwindcss/components";
@import "tailwindcss/utilities";

.container-bg {
    min-width: 1690px;
    max-width: 1690px;
    left: 50%;
    transform: translateX(-50%);
}

html,
body {
    overflow-x: hidden;
}

html{
    position: relative;
}

body:lang(vi) {
     font-family: system-ui, -apple-system, /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !important;
}