.preloader-bounce,
.preloader-bounce-2 {
  @apply w-8 h-8 rounded-full bg-blaze opacity-60 absolute;
  animation: 2s ease 0s normal none infinite bounce;
}
.preloader-bounce-2 {
  animation-delay: -0.5s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
