ul.goals-legend {
  @apply list-none w-full text-white-700 flex flex-row flex-wrap items-start;
}

ul.goals-legend li {
  width: 25%;
  @apply inline-block text-center text-xs mt-12 relative;

  @screen md {
    width: 18%;
  }

  &:before {
    display: block;
    content: "";
    border-bottom: 8px solid red;
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    height: 0;
    position: absolute;
    top: -28px;
    width: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    position: absolute;
    display: block;
    content: "";
    height: 12px;
    width: 20px;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
  }
}
@each $index, $color in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10), (#b02d4b, #d7524c, #ee8362, #f5b67a, #d9e99b, #b1d981, #b1d981, #7ec27b, #42a56c, #2d7f59) {
  ul.goals-legend li:nth-child($(index)) {
    &::before {
      border-bottom-color: $(color);
    }
    &::after {
      background-color: $(color);
    }
  }
}
