@layer base {
  h1 {
    @apply font-bold text-6xl leading-normal;
  }

  h2 {
    @apply font-bold text-2xl leading-tightest;
  }

  h3 {
    @apply font-bold text-xl;
  }

  h4 {
    @apply font-bold text-md;
  }

  h5 {
    @apply font-bold;
  }

  @screen md {
    h1 {
      @apply text-6xl leading-normal;
    }

    h2 {
      @apply text-5xl;
    }

    h3 {
      @apply text-2xl;
    }
  }
}

@layer utilities {
  .copy {
    @apply text-md leading-normal;
  }

  .redactor {
    @apply text-base;

    h1 {
      @apply text-6xl mb-4;
    }

    h2 {
      @apply font-bold text-5xl leading-tightest mb-10px;
    }

    h3 {
      @apply text-2xl my-10px;
    }

    h4 {
      @apply text-lg my-10px;
    }

    ul,
    ol,
    p {
      @apply leading-normal mb-5 min-h-1 text-gray;
    }

    ul:not(.list-checkmark) {
      @apply list-disc pl-8;
    }

    ol {
      @apply list-decimal pl-8;
    }

    p a:not(.button) {
      @apply text-blaze hover:text-black transition duration-200;
    }

    hr {
      @apply my-4;
    }

    strong {
      @apply text-black;
    }

    .text\-blaze {
      @apply text-blaze;
    }

    .text\-gray {
      @apply text-gray;
    }

    .text\-white {
      @apply text-white;
    }

    &.text-white p,
    &.text-white ul,
    &.text-white ol {
      @apply text-white;
    }

    &.text-dusk p,
    &.text-dusk ul,
    &.text-dusk ol {
      @apply text-dusk;
    }
  }

  @variants responsive {
    .redactor-sm {
      @apply text-sm;
    }

    .redactor-md {
      @apply text-md;
    }

    .redactor-xl {
      @apply text-xl;
    }

    .redactor-sm-titles h2 {
      @apply text-3xl;
    }
  }
}

.headline-break {
  display: none;
}
@screen lg {
  .headline-break {
    display: block;
  }
}
