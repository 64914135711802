.container,
.lg\:container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@screen sm {
  .container {
    max-width: 540px;
  }
}

@screen md {
  .container {
    max-width: 720px;
  }
}

@screen lg {
  .container {
    max-width: 960px;
  }
}

@screen xl {
  .lg\:container {
    max-width: 1140px;
  }

  .container {
    max-width: 1140px;
  }
}
