@import "flickity/css/flickity.css";

.flickity-page-dots {
  bottom: 0;
}
.flickity-page-dots .dot.is-selected {
  @apply bg-blaze;
}

.flickity-page-dots .dot {
  @apply bg-gray-300 opacity-50 mx-1;
}
