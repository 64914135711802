html {
  scroll-behavior: smooth;
}

[x-cloak] {
  display: none !important;
}

p, h1, h2, h3, li, b, span{
  word-break: keep-all !important;
}