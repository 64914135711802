.list-checkmark {
  @apply pl-7;
}
.list-checkmark li {
  @apply relative;
}
.list-checkmark li::before {
  content: "";
  @apply w-6 h-4 block absolute;
  left: -1.5rem;
  top: 0.4rem;
  background: url("/build/assets/images/check.svg") no-repeat;
}
